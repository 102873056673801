import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="injekto-akupunktur" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Injekto-Akupunktur`}</h1>
      <h3 {...{
        "id": "injekto-akupunktur"
      }}>{`Injekto-Akupunktur`}</h3>
      <p>{`Aus der chinesischen Akupunktur-Lehre sind Energiebahnen (Meridiane) und Hunderte von Akupunkturpunkten auf unserer Hautoberfläche bekannt. Diese Punkte können mit unterschiedlichen Techniken behandelt werden: eben mit Nadeln, aber auch durch Wärme (Moxibustion), Druck (Akupressur), mit Laserstrahlen, Infrarotlicht, Elektrostimulation usw.`}</p>
      <p>{`Eine weitere seit langem bewährte Behandlungsform ist die Injektion von naturheilkundlichen Medikamenten aus Phytotherapie und Homöopathie per Spritze und Kanüle in die entsprechenden Punkte.`}</p>
      <p>{`Durch die Kombination von Medikamenten-Injektion und die energetische Therapie über die Akupunkturpunkte ist eine Wirkungsverstärkung erreichbar.`}</p>
      <p>{`Die Hauptanwendungsgebiete sind:`}</p>
      <ul>
        <li parentName="ul">{`Nervenschmerzen`}</li>
        <li parentName="ul">{`Ohrgeräusche`}</li>
        <li parentName="ul">{`Kopfschmerzen/Migräne`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/beschwerdebilder/rueckenbeschwerden"
          }}>{`Rückenbeschwerden`}</a>{`, Gelenkschmerzen`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/beschwerdebilder/arthrose_rheuma"
          }}>{`Arthrosen / Rheuma`}</a></li>
        <li parentName="ul">{`Magen-/Bauchschmerzen u.a.m.`}</li>
      </ul>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      